
import {Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, FormControl, Select, Box, Icon, Button, InputRightElement, InputGroup, Input, Checkbox, } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {MdOutlineSearch, MdOutlineArrowDropDown, MdOutlineArrowDropUp, MdOutlineLoop} from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";

// Custom components
import Card from "components/card/Card";
import TableMenu from "views/global/TableMenu";
import { QuickAlert } from "theme/components/alerts";
import { Link } from "react-router-dom";




export default function SimpleDataTable(props) {


  //PROPS
  const {src, title, menuItems, search, checkbox, checkboxMenu, tableMenu, external, additionalOptions, trigger} = props;
  //STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [sortings, setSortings] = useState({sort: "id", sortby: "desc"});
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [tableData, setTableData] = useState({
    columns: [],
    rows: {
      data: [],
      links: [],
      total: 0
    }
  });
  const [contentLoading, setContentLoading] = useState(false);
  const [checked, setChecked] = useState({});
  const [unchecked, setUnchecked] = useState(false);
  const [perPage, setPerPage] = useState(10);

  //IMPORT STATES
  const { setAlert, clearAlert, showAlert } = QuickAlert();

  //VARIABLES
  const getToken = JSON.parse(localStorage.getItem('token'));
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "brand.300");
  const alignText = title ? null : "flex-end" ;
  const checkboxAction = Object.keys(checked).filter((key) => checked[key] === true).length;
  const totalCount = tableData.rows.total;


  //GENERATE EXTERNAL LINKS
  const externalLinkGenerator =(link, type) => {

    const matches = link.match(/\[(.*?)\]/g);
    matches.map((match) => {
      const key = match.replace("[","").replace("]","");
      link = link.replace(match, type[key]);
    });
    return link;
  }


  //GENERATE SPECIAL CLASS
  const generateClass = (string) => {
    if(string === null) return null;
    if(!isNaN(string)) return "data-table-single-integer";
    const sanitizedValue = string.replace(/[^a-zA-Z\s]/g, ''); // Keep only alphabetic characters and spaces
    const classes = "data-table-single-"+sanitizedValue.split(' ')[0].toLowerCase()
    return classes;
  };
  
  //GET DATA FROM API
  const getContactList = () =>{
    setContentLoading(true);
    setChecked({});
    return axios.get(process.env.REACT_APP_API+src, {
      params: {
        perPage:perPage, 
        page:currentPage, 
        sortType:sortings.sort, 
        sortData:sortings.sortby, 
        search:searchKeyword,
        ...additionalOptions
      },
      headers: {
        'Content-Type': 'application/json',
        'Accept' : 'application/json',
        'Authorization': `Bearer ${getToken}`
      }
    }).then(response => {
      return response;
    }).then(response => {
      setTableData(response.data);
      setContentLoading(false);
    }).catch(function(error) {
      setContentLoading(false);
      setAlert("error",error.response.data.errors);
    });
  };

  //MOUNT DATA EVERYTIME STATE CHANGES
  useEffect(() => {
    getContactList();
  }, [currentPage,sortings,perPage, searchKeyword, trigger]);

  //TABLE VARIABLES
  const columns = useMemo(() => tableData.columns, [tableData.columns]);
  const data = useMemo(() => tableData.rows.data, [tableData.rows.data]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 1000;

  //HANDLER TO CHECKED BOXES
  const handleCheckboxChange = (status, id) => {
    setChecked((prev) => ({
        ...prev,
        [id]: status,
    }));
  };

  //HANDLER TO CLEAR CHECKED BOXES
  const handleClear = () => {
    return () => {
      setUnchecked(true);
    }
  }
  
  const allCheckboxHandler = (checked) => {
    tableData.rows.data.map((row) => {
      row.checked = checked;
      handleCheckboxChange(checked, row.id)
    });
  }

  useEffect(() => {
    tableData.rows.data.map((row) => {
      row.checked = false;
      handleCheckboxChange(false, row.id)
    });
    setUnchecked(false);
  }, [unchecked])

  //HANDLE SEARCH BAR
  const handleSearch = (keyword) => {
    if(keyword.length >= 3) {
        setSearchKeyword(keyword);
    }else if(keyword.length <= 0 && searchKeyword !== null) {
        setSearchKeyword(null);
    }
  };

  //HANDLE PAGE CHANGE
  const handlePageChange = (page) => {
      setCurrentPage(page);
  }

  //HANDLE PER PAGE CHANGE
  const handlePerPageChange = (perPage) => {
      setPerPage(perPage);
      const MaxPage = Math.ceil(totalCount / perPage);
      if(MaxPage < currentPage){
          setCurrentPage(MaxPage);
      }
  }
  //HANDLE SORT CHANGE
  const handleSortChange = (id, by) => {
      setSortings({sort: id, sortby: by});
  }

  //TABLE BODY COMPONENT
  const TableBody = () => {
    return (
      <Tbody {...getTableBodyProps()}>
       
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              { checkbox ? <Td
              fontSize={{ sm: "14px" }}
              borderColor='transparent'
              width="1%"
              minW="50px"
              >
                <Checkbox 
                key = {index}
                onChange={
                  (e) => {
                    handleCheckboxChange(e.target.checked, row.original.id)
                    row.original.checked = e.target.checked;
                  }
                }
                isChecked={row.original.checked}
                />
              </Td> : null}
              {row.cells.map((cell, index) => {
                //cell.column.id
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='500' className={"data-table-item-"+cell.column.id +" "+(generateClass(cell.value))}>
                        {external ? 
                        Object.entries(external).map(([key, link]) => {

                          if(cell.column.id === key){
                            return (
                              <Link
                              key={index}
                              to={externalLinkGenerator(link,row.original)}
                              className="primary-color fw-bold"
                              >
                                {cell.value}
                              </Link>
                            )
                          }else{
                            return cell.value;
                          }
                        })
                        : cell.value}
                      </Text>
                    </Flex>
                  </Td>
                );
              })}
              {tableMenu ? <Td
              fontSize={{ sm: "14px" }}
              borderColor='transparent'
              width="1%"
              minW="100px"
              >
              <TableMenu menuItems={tableMenu.map(item => ({
                  ...item,
                  title: typeof item.title === 'function' ? item.title(row.original) : item.title,
                  icon: typeof item.icon === 'function' ? item.icon(row.original) : item.icon,
              }))} row={row.original} />
              </Td> : null}
            </Tr>
          );
        })}
      </Tbody>
    )
};

return (
  <Card
    direction='column'
    w='100%'
    p='0'
    borderRadius='0'
    overflowX={{ sm: "scroll", lg: "hidden" }}>
    
    {title || menuItems ? <Flex justify='space-between' mb='40px' align='center' justifyContent={alignText}>
      
      { title ? <Text
        color={textColor}
        fontSize='22px'
        fontWeight='700'
        lineHeight='100%'>
        {title}
      </Text> : null}

      { menuItems ? <TableMenu menuItems={menuItems} /> : null}

    </Flex>: null}
    
    <Flex justify='space-between' align='center' mb="20px">
      <Flex
        w="50%"
        maxW="325px"
        justify='space-between' 
        align='center'
      >
      { search ? <InputGroup size="md">
        <Input
          pr="4.5rem"
          type="text"
          placeholder="search..."
          borderRadius="16px"
          _focus={{borderColor: "gray.300"}}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <InputRightElement width="2.5rem">
          <Icon as={MdOutlineSearch} color="gray.300" fontSize="22px"  />
        </InputRightElement>
      </InputGroup> : null}
      </Flex>
      <Flex
       justify='space-between' 
       align='center'>
        {checkboxAction  ? 
          Object.keys(checkboxMenu).map((key, index) => {
            return (
              <Button 
                leftIcon={checkboxMenu[key].icon} 
                variant="outline"
                me={2}
                fontWeight="light" 
                colorScheme={checkboxMenu[key].color}
                onClick={ () => checkboxMenu[key].handle(checked)}
                key={index}
                >
                {checkboxMenu[key].name}
              </Button>
            )
          })
        : null}
        {checkboxAction  ? 
          <Button 
            leftIcon={<MdOutlineLoop />} 
            variant="outline"
            me={2}
            colorScheme="gray"
            onClick={handleClear()}
            fontWeight="light" 
            >
            Clear
          </Button>
        : null}
      </Flex>
    </Flex>

    <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {checkbox ? <Th
            pe='10px'
            borderColor={borderColor}
            width={5}
            >
              <Checkbox 
                onChange={
                  (e) => {
                    allCheckboxHandler(e.target.checked)
                  }
                }
                isChecked={tableData.rows.data.every((row) => row.checked)}
              />
            </Th> : null}
            {headerGroup.headers.map((column, index) => (
              <Th
                pe='10px'
                key={index}
                borderColor={borderColor}
                cursor="pointer"
                onClick={() => handleSortChange(column.id, sortings.sortby==="desc" ? "asc" : "desc")}
                >
                <Flex
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  >
                  {column.render("Header")} {sortings.sortby==="asc" && sortings.sort===column.id ? <MdOutlineArrowDropDown size={24} /> : sortings.sortby==="desc" && sortings.sort===column.id ? <MdOutlineArrowDropUp size={24} /> : null}
                </Flex>
              </Th>
            ))}
            {tableMenu ? <Th
            pe='10px'
            borderColor={borderColor}
            width={5}
            >
              Action
            </Th> : null}
          </Tr>
        ))}
      </Thead>
     {contentLoading===false ? TableBody() : null}
    </Table>
    <Flex justifyContent="center">
        {contentLoading===true ? <BeatLoader
        loading="true"
        size={15}
        aria-label="Loading Spinner"
        color="#422CFA"
        />:  totalCount === 0 ?
          <Text
          width="100%"
          textAlign="center"
          color="gray.400"
          >
            No records found
          </Text> 
          : null}
     </Flex>
     
    {totalCount > 0 ? <Flex justify='space-between' align='center'>
      <Flex
        w="50%"
        align='left'
      >
        <FormControl 
          maxWidth="80px"
          me={5}
        >
          <Select 
            _focus={{
              borderColor: "gray.300",
            }}
            defaultValue={perPage}
            onChange={(e) => handlePerPageChange(e.target.value)}
          >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>500</option>
          </Select>
        </FormControl>
        <Text
        alignContent="center"
        >
          Showing {currentPage === 1 ? 1 : ((currentPage - 1) *  perPage) +1} to {(currentPage *  perPage) > totalCount ? totalCount : currentPage *  perPage} of {totalCount} records
        </Text>
      </Flex>
      <Flex
       justify='space-between' 
       align='center'>
        {tableData.rows.links && perPage <= totalCount ? 
          Object.entries(tableData.rows.links).map(([key, value]) => {
            if (value.label === "&laquo; Previous" || value.label === "Next &raquo;") return null;
            return (
              <Button
              variant={value.active ? "solid" : "outline"}
              colorScheme={value.active ? "brand" : "gray"} 
              size="sm"
              me={2}
              key={key}
              onClick={() => handlePageChange(value.label)}
              >
                {value.label}
              </Button>
            )
          })
        :null}
      </Flex>
    </Flex> : null }
  </Card>
);

}
