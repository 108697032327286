import { React, useState, useEffect } from "react";
import { Box, Flex, Button} from '@chakra-ui/react';
import { MdDeleteOutline, MdEdit, MdPerson, MdOutlineRefresh} from "react-icons/md";
import Card from "components/card/Card.js";
import SidebarScroll from 'components/scroll/SidebarScroll';
import { QuickAlert } from "theme/components/alerts";
import SimpleDataTable from "views/global/SimpleDataTable";
import SimpleSidebar from "views/global/SimpleSidebar";
import FormGenerate from "views/global/FormGenerate";
import { userController } from 'lib/user';
import handleConfirmation from 'lib/handleConfirmation';
import handleSidebar from "lib/handleSidebar";
import { PropertyOptions } from "lib/PropertyOptions";


function PropertyManagement() {
    //IMPORT CONTROLLER
    const { setAlert, clearAlert, showAlert } = QuickAlert();
    const {saveRecord, getSingleRecord, userDetail} = userController();
    const { getUser } = userDetail();
    const advanceGroup = ["admin","office"].includes(getUser.role);
    
    const [status, setStatus] = useState({
        sidebarForm: false,
        loading: false,
        formLoading: false,
        saveNewList: false,
        editListEnabled: false,
        fetchingProperties: false,
    });
    const [input, setInput] = useState({});
    const fieldNames = [
        {label: "Identifier", name:"identifier", type:"text", required: true},
        {label: "Address", name:"address", type:"text", required: true},
        {label: "Description", name:"description", type:"textarea", required: false},
        {label: "Doorbell Name", name:"doorbell_name", type:"text", required: false},
        {label: "Check-In Type", name:"checkin_type", type:"select", required: true, options: ["self","non self","tipologia sistema"]},
        {label: "CheckIn Comments", name:"checkin_comment", type:"text", required: false},
        {label: "Keys", name:"keys", type:"text", required: false},
        {label: "Elevator", name:"elevator", type:"select", required: true, options: ["si","no"]},
        {label: "Floor", name:"floor", type:"select", required: true, options: [0,1,2,3,4,5,6,7,8,9,10]},
        {label: "Entrance", name:"entrance", type:"textarea", required: false},
        {label: "Counter", name:"counter", type:"text", required: false},
        {label: "Max Guests", name:"max_guest", type:"select", required: false, options: [1,2,3,4,5,6,7,8,9,10]},
        {label: "MQ", name:"mq", type:"text", required: false},
        {label: "Exterior", name:"exterior", type:"multiple", multipleFields:[{name:"label",type:"select", options:PropertyOptions.exterior, placeholder:"exterior"}, {name:"value",type:"select", options: [1,2,3,4,5,6,7,8,9,10], placeholder:"number"}], required: false, design: "horizontal"},
        {label: "Appliance", name:"appliance", type:"multiple", multipleFields:[{name:"label",type:"select", options:PropertyOptions.appliance, placeholder:"appliance"},{name:"value",type:"text", placeholder:"information"}], required: false, design: "horizontal"},
        {label: "Facilities", name:"facilities", type:"multiple", multipleFields:[{name:"label",type:"select", options:PropertyOptions.facilities, placeholder:"facility"}, {name:"value",type:"text", placeholder:"information"}], required: false, design: "horizontal"},
        {label: "Property Owner", name:"owner", type:"text", required: false},
        {label: "Contract Type", name:"contract_type", type:"text", required: false},
        {label: "Contract Note", name:"contract_note", type:"textarea", required: false},
    ]
    const trigerSaveList = status.saveNewList ? false : true;

    //CHECKBOX MENU
    const checkboxMenu = [
        {
            icon: <MdDeleteOutline />,
            name: "Delete",
            color: "red",
            handle : (checked) => handleConfirmation(checked, "delete", "property", "Deleting selected property will remove all associated data as well. This action cannot be undone.", "/user/property/delete",(response) => {
                clearAlert();
                setStatus(prevStatus => ({
                    ...prevStatus,
                    saveNewList: trigerSaveList,
                }));
                setAlert(response.data.status, response.data.message);
            },
            (error) => {
                setAlert("error",error.response.data.errors);
            }),
        }
    ];

    //CREATE LIST SHOW & HIDE SIDEBAR
    const createList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: true,
            editListEnabled: false
        }));
        setInput({});
        handleSidebar();
    }
    const hideList = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: false,
            editListEnabled: false
        }));
        setInput({});
        handleSidebar(false);
        clearAlert();
    }

    //SAVE LIST DETAIL
    const saveListHandle = () => {
        setStatus(prevStatus => ({
            ...prevStatus,
            loading: true,
        }));
        saveRecord('/user/property/create',input).then(response => {
            return response;
        }).then(response => {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
                saveNewList: trigerSaveList,
            }));
            hideList();
        }).catch(function(error) {
            setStatus(prevStatus => ({
                ...prevStatus,
                loading: false,
            }));
            setAlert("error",error.response.data.errors);
            SidebarScroll();
        });
    }
    //FETCH PROPERTIES
    const fetchProperties = () => {
        //SET STATUS FETCHING PROPERTIES
        setStatus(prevStatus => ({
            ...prevStatus,
            fetchingProperties: true,
        }));
        //SEND REQUEST
        getSingleRecord("/user/property/fetch").then(response => {
            return response;
        }).then(response => {
            setStatus(prevStatus => ({
                ...prevStatus,
                saveNewList: trigerSaveList,
                fetchingProperties: false,
            }));
        }).catch(function(error) {
            setAlert("error",error.response.data.errors);
            setStatus(prevStatus => ({
                ...prevStatus,
                fetchingProperties: false,
            }));
        });
    }
    //EdIT LIST SAVE
    const editListHandle = (row) => {
        setStatus(prevStatus => ({
            ...prevStatus,
            sidebarForm: true,
            editListEnabled: true,
            formLoading: true
        }));
        handleSidebar();
        getSingleRecord("/user/property/get/"+row.id).then(response => {
            return response;
        }).then(response => {
            setInput((prev) => ({
                ...prev,
                ...response.data.item,
            }));
            setStatus(prevStatus => ({
                ...prevStatus,
                formLoading: false
            }));
        }).catch(function(error) {
            setAlert("error",error.response.data.message);
            setStatus(prevStatus => ({
                ...prevStatus,
                formLoading: false
            }));
        });
    }
    //HIDE SIDEBAR
    useEffect(() => {
        clearAlert();
    }
    ,[status.sidebarForm, status.editListEnabled]);

    const tableMenuItems = [
        {
            icon: <MdEdit />,
            title: "Edit Property",
            handle : editListHandle,
        },
    ];

    return (
        <Box  pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Flex justify='space-between' mb='20px' justifyContent="flex-end">
                {advanceGroup && (
                    <>
                    <Button
                    variant="outline"
                    colorScheme="brand"
                    fontWeight="light"
                    leftIcon={<MdPerson />}
                    _hover={{ bg: "brand.500", color: "white"}}
                    transition='background-color 0.2s linear'
                    onClick={createList}
                    me="10px"
                    >
                        Create New Property
                    </Button>
                    <Button
                    variant="brand"
                    leftIcon={<MdOutlineRefresh className={status.fetchingProperties ? "rotate-icon" : "static-icon"}/>}
                    _hover={{ bg: "brand.500", color: "white"}}
                    onClick={fetchProperties}
                    disabled={status.fetchingProperties ? true : false}
                    >
                        {status.fetchingProperties ? "Fetching..." : "Fetch Properties"}
                    </Button>
                    </> 
                )}
            </Flex>
            <Card mb='20px' p='40px'>
                {status.sidebarForm===false ? showAlert() : null }
                <SimpleDataTable
                    title="All Properties"
                    src="/user/property/list"
                    search={true}
                    checkbox={advanceGroup ? true : false}
                    checkboxMenu={advanceGroup ? true : false}
                    tableMenu={advanceGroup ? tableMenuItems : false}
                    trigger={status.saveNewList}
                    external={{identifier:'/user/property/management/view/[id]'}}
                />
            </Card>
            <SimpleSidebar
            status={status.sidebarForm}
            title={status.editListEnabled ? "Edit Property" : "Create New Property"}
            save={{onClick:saveListHandle,isLoading:status.loading,text:status.editListEnabled ? "Update" : "Create Property"}}
            cancel={{onClick:hideList, text:"Cancel"}}
            loading={status.formLoading}
            >
                {status.sidebarForm===false ?  null : showAlert() }
                {status.sidebarForm===true &&
                    <FormGenerate fields={fieldNames} setFields={setInput} defaultValue={input} />
                }
            </SimpleSidebar>
        </Box>

    );
}
export default PropertyManagement;
